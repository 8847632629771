<template>
  <div class="menu">
    <router-link class="menu__logo" to="/">
      <img src="../assets/Crown_Logo_light.svg" alt="" />
    </router-link>
    <div class="menu__header" v-if="!user.loggedIn">
      <div
        v-for="(i, index) in menuCrown"
        :key="index"
        class="menu__header--item"
      >
        <router-link :to="i.link">
          {{ i.item }}
        </router-link>
      </div>
    </div>
    <div class="menu__header" v-if="user.loggedIn">
      <div
        v-for="(i, index) in menuCrownUser"
        :key="index"
        class="menu__header--item"
      >
        <div v-if="i.item != 'My Flat' && i.item != 'Sign Out'">
          <router-link :to="i.link">
            {{ i.item }}
          </router-link>
        </div>
        <div v-else-if="i.item == 'My Flat'">
          <a class="nav-link" @click.prevent="myFlat">Wohnungsauswahl</a>
        </div>
        <div v-else-if="i.item == 'Sign Out'">
          <a class="nav-link" @click.prevent="signOut">Abmelden</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import axios from 'axios'

export default {
  data() {
    return {
      menuCrown: [
        { item: '360° Rundgang', link: '/rundgang' },
        { item: 'Wohnungsauswahl', link: '/flats' },
        { item: 'Kontakt', link: '/kontakt' },
        { item: 'Impressum', link: '/impressum' },
        { item: 'Datenschutz', link: '/datenschutz' }
      ],
      menuCrownUser: [
        { item: '360° Rundgang', link: '/rundgang' },
        { item: 'My Flat', link: '' },
        { item: 'Kontakt', link: '/kontakt' },
        { item: 'Impressum', link: '/impressum' },
        { item: 'Datenschutz', link: '/datenschutz' }
      ],
      flats: {},
      rooms: {}
    }
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user"
    })
  },
  name: 'Menu',
  props: {
    menu: Array
  },
  methods: {
    myFlat() {
      if (this.user.data.displayName == 'Superadmin') {
        this.$router.push({ name: 'Flats' })
      }
      else {
        axios.get('https://cadman-63dc7.firebaseio.com/Flats.json')
        .then((response) => {
          this.flats = response.data
        })
        .finally(() => {
          for (let key in this.flats) {
            if (this.flats[key].Data.FlatID == localStorage.getItem('crownBayerFlatId')) {
              axios.get('https://cadman-63dc7.firebaseio.com/Flats/' + key + '/.json')
                .then((response) => {
                  this.rooms = response.data
                })
                .finally(() => {
                  this.$router.push({ name: 'Flat', params: { flat: key, number: this.rooms.Data.displayName, rooms: this.rooms, flatID: localStorage.getItem('crownBayerFlatId'), user: localStorage.getItem('crown'), action: 'design', roomStart: 'Wohnen', styleStart: 'SILK', framedMeters: this.rooms.Data.Square, cost: this.rooms.Data.UDID } });
                })
            }
          }
        })
      }
    },
    signOut() {
      alert('Good by')
      localStorage.removeItem("crown");
      localStorage.removeItem("crownBayerId");
      axios.get('https://us-central1-cadman-63dc7.cloudfunctions.net/getAnonUser')
        .then((result) => {
          this.crownKey = result.data
        })
        .finally(() => {
          localStorage.setItem('crown', this.crownKey);
        });
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push('/').catch(() => { });
        });
    }
  }
}
</script>

<style scope>
.menu__logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.menu__logo img {
  width: 160px;
}
.menu {
  padding-top: 2rem;
  margin-bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(50, 58, 65, 0.8) 0%,
    rgba(50, 58, 65, 0) 100%
  );
}
/*.menu__header {*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*  justify-content: space-between;*/
/*  margin-top: 2rem;*/
/*  margin-left: 12rem;*/
/*  margin-right: 12rem;*/
/*}*/
/*.menu__header--item {*/
/*  text-shadow: 1px 1px 1px #323a41;*/
/*}*/
.menu__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1178px;
  margin: 0em auto;
}
.menu__header--item {
  text-align: center;
  margin-top: 0.5em;
}
.menu__header--item a {
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  text-decoration: none;
  color: #fff;
  padding-bottom: 0.5em;
  cursor: pointer;
  margin: 1em;
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
  display: inline-block;
  margin-right: 0;
  margin-left: 0;
}
.menu__header--item a:hover,
.menu__header--item a.router-link-active {
  color: #ad8059;
  /*text-shadow: 0px 0px 0px #323a41;*/
}
</style>
